//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import CONFIG from "../../config/default";
import { compareObjects } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Checkbox, FormGroup, FormHelperText, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import Establishment from "../../models/Establishment";
import { getServerUrl } from "../../config";

export const EstablishmentsDialog = ({ isOpen, onClose, title, establishment }) => {
    const theme = useTheme();
    const { lists } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const { presentAlertDialog } = useDialog();
    const { control, handleSubmit, reset, getValues, unregister, watch, formState } = useForm({
        mode: "onChange"
    });

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customer_hid = params.get('customer_hid');

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        //reset formValues
        reset();
    }, [establishment])



    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit(async (data) => {
        let formValues = { ...data };

        // copy customer then overrides customer values by formValues
        let establishmentTmp = new Establishment({ ...establishment, ...formValues });
        let response = undefined;

        if (establishment?.clientAction === "new") {
            response = await httpRequest({
                url: `${getServerUrl()}/admin/customer/${customer_hid}/establishment/create`,
                data: { establishment: establishmentTmp },
                method: "post",
                headers: { "Content-type": "Application/json" },
                withCredentials: true
            }, true, true);
        } else {
            //check if establishment has been altered
            let hasChanged = !compareObjects({ obj1: new Establishment(establishment), obj2: establishmentTmp, falsyEquals: true, includeObj2Keys: true });
            if (hasChanged) {
                response = await httpRequest({
                    url: `${getServerUrl()}/admin/customer/${customer_hid}/establishment/update`,
                    data: { establishment: establishmentTmp },
                    method: "post",
                    headers: { "Content-type": "Application/json" },
                    withCredentials: true
                }, true, true);

            } else {
                presentToast({
                    severity: "warning",
                    message: `${title}: aucun champ modifié`
                })
            }
        }
        if (response?.status === 200) {
            presentToast({
                severity: "success",
                message: `${title}: succès`
            })
            onClose();
        }
    })


    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Identification établissement</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="name"
                                        rules={{
                                            required: "Nom de l'établissement requis",
                                            minLength: {
                                                value: 3,
                                                message: '3 caractères min'
                                            }
                                        }}
                                        defaultValue={establishment?.name || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom de l'établissement"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="legal_person"
                                        rules={{
                                            required: "Personne morale requise",
                                            minLength: {
                                                value: 3,
                                                message: '3 caractères min'
                                            }
                                        }}
                                        defaultValue={establishment?.legal_person || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Personne morale"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="finess_no"
                                        rules={{
                                            required: "Numéro FINESS requis",
                                            minLength: {
                                                value: 9,
                                                message: '9 caractères requis'
                                            },
                                            maxLength: {
                                                value: 9,
                                                message: '9 caractères requis'
                                            },
                                            pattern: {
                                                value: /^\d+$/,
                                                message: 'Doit être un nombre'
                                            }
                                        }}
                                        defaultValue={establishment?.finess_no || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Numéro FINESS"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="capacity"
                                        rules={{
                                            required: "Capacité requise",
                                            pattern: {
                                                value: /^\d+$/,
                                                message: 'Doit être un nombre'
                                            }
                                        }}
                                        defaultValue={establishment?.capacity || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Capacité"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="website"
                                        rules={{
                                            pattern: {
                                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/,
                                                message: 'URL invalide'
                                            }
                                        }}
                                        defaultValue={establishment?.website || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Site internet"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="is_headquarter"
                                        rules={{}}
                                        defaultValue={establishment?.is_headquarter || false}
                                        control={control}
                                        render={({ field }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard">
                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                checked={value}
                                                                onChange={event => onChange(event?.target?.checked)}
                                                            />
                                                        } label="siège du groupe" labelPlacement="end" />
                                                    </FormGroup>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="activity"
                                        rules={{
                                            required: {
                                                value: !getValues("is_headquarter"),
                                                message: 'Requis excepté pour le siège'
                                            }
                                        }}
                                        defaultValue={establishment?.activity || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <InputLabel>Activité</InputLabel>
                                                    <Select
                                                        // label="Site internet"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        // autoWidth
                                                        MenuProps={MenuProps}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                    >
                                                        {Object.keys(lists?.ACTIVITY_LIST).map(a => (
                                                            <MenuItem key={a} value={a}>{`${lists?.ACTIVITY_LIST[a]?.short}: ${lists?.ACTIVITY_LIST[a]?.label}`}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Directeur</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="director.firstname"
                                        rules={{}}
                                        defaultValue={establishment?.contact?.firstname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Prénom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="director.lastname"
                                        rules={{}}
                                        defaultValue={establishment?.contact?.lastname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="director.job"
                                        rules={{}}
                                        defaultValue={'directeur'}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Poste"
                                                        variant="standard"
                                                        disabled
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="director.tel"
                                        rules={{
                                            pattern: {
                                                value: /^(?:(?:\+|00)33|0)\d{9}$/,
                                                message: "Numéro de téléphone invalide"
                                            }
                                        }}
                                        defaultValue={establishment?.contact?.tel || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Tel"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="director.fax"
                                        rules={{
                                            pattern: {
                                                value: /^(?:(?:\+|00)33|0)\d{9}$/,
                                                message: "Numéro de fax invalide"
                                            }
                                        }}
                                        defaultValue={establishment?.contact?.fax || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Fax"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="director.email"
                                        rules={{
                                            pattern: {
                                                value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
                                                message: "Email invalide"
                                            }
                                        }}
                                        defaultValue={establishment?.contact?.email || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Email"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion><Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Contact qualité</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="contact.firstname"
                                        rules={{}}
                                        defaultValue={establishment?.contact?.firstname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Prénom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.lastname"
                                        rules={{}}
                                        defaultValue={establishment?.contact?.lastname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.job"
                                        rules={{}}
                                        defaultValue={establishment?.contact?.job || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Poste"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.tel"
                                        rules={{
                                            pattern: {
                                                value: /^(?:(?:\+|00)33|0)\d{9}$/,
                                                message: "Numéro de téléphone invalide"
                                            }
                                        }}
                                        defaultValue={establishment?.contact?.tel || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Tel"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.fax"
                                        rules={{
                                            pattern: {
                                                value: /^(?:(?:\+|00)33|0)\d{9}$/,
                                                message: "Numéro de fax invalide"
                                            }
                                        }}
                                        defaultValue={establishment?.contact?.fax || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Fax"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.email"
                                        rules={{
                                            pattern: {
                                                value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
                                                message: "Email invalide"
                                            }
                                        }}
                                        defaultValue={establishment?.contact?.email || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Email"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Adresse</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="address.street_number"
                                        rules={{}}
                                        defaultValue={establishment?.address?.street_number || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Numéro de rue"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="address.street_name"
                                        rules={{ required: 'Nom de rue requis' }}
                                        defaultValue={establishment?.address?.street_name || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom de rue"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="address.complement"
                                        rules={{}}
                                        defaultValue={establishment?.address?.complement || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Complement"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="address.zipcode"
                                        rules={{
                                            required: 'Code postal requis',
                                            minLength: {
                                                value: 5,
                                                message: '5 caractères requis'
                                            },
                                            maxLength: {
                                                value: 5,
                                                message: '5 caractères requis'
                                            },
                                            pattern: {
                                                value: /^\d+$/,
                                                message: 'Code postal invalide'
                                            }
                                        }}
                                        defaultValue={establishment?.address?.zipcode || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Code postal"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="address.city"
                                        rules={{ required: 'Ville requise' }}
                                        defaultValue={establishment?.address?.city || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Ville"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Modules débloqués</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="unlocked_modules"
                                        rules={{}}
                                        defaultValue={establishment?.unlocked_modules || []}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value } = field;

                                            const handleChange = (optionValue) => {
                                                // Check if the value is already present in the array
                                                if (value.includes(optionValue)) {
                                                    // If present, remove it
                                                    onChange(value.filter((v) => v !== optionValue));
                                                } else {
                                                    // If not present, add it
                                                    onChange([...value, optionValue]);
                                                }
                                            };

                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={!!error}>
                                                    <FormGroup>
                                                        {lists.LICENSE_MODULES_LIST?.map((option, index) => (
                                                            <FormControlLabel
                                                                key={index}
                                                                control={
                                                                    <Checkbox
                                                                        checked={value.includes(option.value)}
                                                                        // onChange={() => handleChange(option.value)}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                onChange([...value, option.value]);
                                                                            } else {
                                                                                onChange(value.filter((v) => v !== option.value));
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                label={option.label}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                        >
                            valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}